import React, { useState, useEffect } from "react"
import axios from "axios"

const EnquiryForm = props => {
  const [SuccessMessage, setSuccessMessage] = useState("hide")
  const [FormContainer, setFormContainer] = useState("show")
  const [ErrorMessage, setErrorMessage] = useState("")
  const [modalTitle, setmodalTitle] = useState("Enquire Now")
  const [Name, setName] = useState("")
  const [Email, setEmail] = useState("")
  const [Phone, setPhone] = useState("")
  const [Message, setMessage] = useState("")
  const [Button, SetButton] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()
    const records = {
        name: Name,
        email: Email,
        phone: Phone,
        message: Message,
      }
      if (Name !== "" && Email !== "" && Phone !=="") {
        SetButton(true)
        setErrorMessage("")
        axios
          .post(`https://spotcloud.in/mail/representcontact.php`, {
            records,
          })
          .then(res => {
            setSuccessMessage("show")
            setName("")
            setEmail("")
            setPhone("")
            setMessage("")
            SetButton(false)
          })
      } else {
        setErrorMessage("Please fill all the mandatory Fields")
      }
  }
  return (
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {modalTitle}
        </h5>
      
      </div>
      <div className="modal-body">
        <form>
          <div className={"formContainer " + FormContainer}>
            <div className="form-group">
              <label htmlFor="Name">Your Name *:</label>
              <input
                type="text"
                name="name"
                className="form-control"
                value={Name}
                onChange={e => {
                  setName(e.currentTarget.value)
                  setErrorMessage("")
                }}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email address *:</label>
              <input
                type="email"
                name="email"
                className="form-control"
                value={Email}
                onChange={e => {
                  setEmail(e.currentTarget.value)
                  setErrorMessage("")
                }}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="Phone">Phone *:</label>
              <input
                type="tel"
                name="phone"
                className="form-control"
                value={Phone}
                onChange={e => {
                  setPhone(e.currentTarget.value)
                  setErrorMessage("")
                }}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="Message">Message :</label>
              <textarea
                type="tel"
                name="Message"
                className="form-control"
                value={Message}
                onChange={e => {
                  setMessage(e.currentTarget.value)
                  setErrorMessage("")
                }}
                required
              />
            </div>
           
           
            <button
              onClick={handleSubmit}
              disabled={Button}
              className="btn btn-default"
            >
              Submit
            </button>
            <p>
              <br></br>
            </p>
          </div>
          <p style={{ color: "red", fontWeight: "bold" }}>{ErrorMessage}</p>
          <div className={"text-center message " + SuccessMessage}>
            <p>
              Thanks for your interest! Our space partner has been notified and
              you shall hear from them shortly.
            </p>
          </div>
        </form>
      </div>
    </div>
  )
}

export default EnquiryForm
