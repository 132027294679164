import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import BannerImage from "../img/banner.jpg"
import HomeBanner01 from "../img/home-banner-01.jpg"
import HomeBanner02 from "../img/home-banner-02.jpg"
import HomeBanner03 from "../img/home-banner-03.jpg"
import HomeBanner04 from "../img/home-banner-04.jpg"
import productImage01 from "../img/products/product01.jpg"
import EnquiryForm from "../components/enquiry"
export default function AboutUs() {
  return (
    <div>
       <Helmet>
        <title>Contact Us - Represent Corporate Giftings</title>
        <meta httpEquiv="content-language" content="en-IN" />
        <meta name="title" content="Contact Us - Represent Corporate Giftings" />
        <meta
          name="description"
          content="Contact Us - #BrandMessengers  #Corporate #TShirts in Chennai @ unmatched price 
          # Corporate TShirt manufacturers in Chennai"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="http://representgiftings.com/bags" />
        <meta
          property="og:description"
          content="Contact Us - #BrandMessengers  #Corporate #TShirts in Chennai @ unmatched price 
          # Corporate TShirt manufacturers in Chennai"
        />
      </Helmet>
      <Layout>
        <div className="pageHeader">
          <h1>Contact Us</h1>
        </div>
        <div className="container paddingSuper">
          <div className="row">
            <div className="col-md-6">
              <h1>GET IN TOUCH</h1><br></br><h3>Represent Gifting Solutions</h3>
             <p><br></br>6/11E, 10th Cross Street,<br></br> Dhandeeswaram nagar, Velachery,<br></br> Chennai - 600042</p> 
             <p><br></br>
              <b>Nijanth Ponnusamy</b> : +91 9867845223<br></br>
              <b>Jithin Mohanan</b> : +91 9048644548 <br></br><br></br><b>Email</b> : info@representgiftings.com</p>
              <br></br>
            </div>
            <div className="col-md-6">
              <EnquiryForm/>
            </div>
          </div>
          <div className="row">
          <div className="col-md-12"><br></br></div>
           
            </div>
        </div>
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6538.622855461685!2d80.22391705301668!3d12.978988435319913!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2e290338a276ae97!2sRepresent%20Gifting%20Solutions!5e0!3m2!1sen!2sin!4v1613908786727!5m2!1sen!2sin" width="100%" height="300"  allowfullscreen="" style={{border:"0"}} loading="lazy"></iframe>
      </Layout>
    </div>
  )
}
